import React, { useRef, useEffect } from "react";

import { makeStyles } from "@mui/styles";

import { initGVBank } from "../core/shared";
import LoadingView from "../tools/LoadingView";

import AudioManager from "./AudioManager";
import CtxScreen from "./CtxScreen";
import Controls from "./Controls";
import Painter from "./Painter";
import Engine from "../core/Engine";

import { useSelector } from "react-redux";

const PlayWindow = () => {
  const classes = useStyles();
  const { objects, tasks, type } = useSelector((s) => s.project);

  const [loading, setLoading] = React.useState(true);

  const xTasks = useRef([]);
  const xObjects = useRef([]);

  function copyData() {
    initGVBank();

    xObjects.current = [];
    xTasks.current = [];

    if (Array.isArray(objects)) {
      objects.forEach((o) => {
        const ss = { ...o.props, clicks: 0, rotate: 0, vars: [] };
        var x = { ...o, status: ss };
        xObjects.current.push(x);
      });
    }

    if (Array.isArray(tasks)) {
      tasks.forEach((t) => {
        var x = { ...t, stp: 0, timer: 0 };
        xTasks.current.push(x);
      });
    }
  }

  useEffect(() => {
    copyData();
  }, [objects, tasks]);

  return (
    <div className={classes.root} dir="ltr" tabIndex="0">
      <div className={classes.playAria}>
        <CtxScreen xObjects={xObjects} mg="0px auto" />
        <LoadingView open={type === null || loading} />
      </div>

      <div className={classes.ctrlAria}>
        <Controls />
      </div>

      <Engine xObjects={xObjects} xTasks={xTasks} copyData={copyData} />
      <Painter
        xObjects={xObjects}
        onLoad={() => {
          setLoading(false);
        }}
      />
      <AudioManager />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    backgroundColor: "#eee",
    width: "100%",
  },
  ctrlAria: {
    display: "block",
    position: "relative",
    width: "100%",
    height: "38px",
  },
  playAria: {
    display: "block",
    position: "relative",
    width: "100%",
    minHeight: "300px",
    maxHeight: "550px",
    overflow: "scroll",
    padding: 2,
    margin: 0,

    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff",
      boxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 4px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#999",
      borderRadius: "3px",
    },
  },
}));

export default PlayWindow;
