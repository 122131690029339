import React from "react";

import { makeStyles } from "@mui/styles";
import { ButtonBase, Menu, MenuItem, CircularProgress } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";

import { gotoPage } from "../tools/browserHelpers";

import LiksAPI from "../api/likesApi";
import { useSelector } from "react-redux";

const LikeControl = () => {
  const classes = useStyles();
  const pid = useSelector((s) => s.project.id);
  const user = useSelector((s) => s.settings.user);
  const texts = useSelector((s) => s.settings.texts.site);

  const [busy, setBusy] = React.useState(true);
  const [menuAncr, setMenuAncr] = React.useState(null);
  const [liksCount, setLiksCount] = React.useState(0);
  const [iLikeit, setIlikeit] = React.useState(false);

  const hCloseMenu = () => {
    setMenuAncr(null);
  };

  const hSignin = () => {
    setMenuAncr(null);
    gotoPage("/signin");
  };

  const hToggleLike = (ev) => {
    if (user) {
      setBusy(true);
      if (iLikeit) {
        LiksAPI.clearLike(
          user,
          pid,
          (m) => {
            setBusy(false);
            setIlikeit(false);
            setLiksCount(liksCount - 1);
          },
          (x) => {
            setBusy(false);
          }
        );
      } else {
        LiksAPI.setLike(
          user,
          pid,
          (m) => {
            setBusy(false);
            setIlikeit(true);
            setLiksCount(liksCount + 1);
          },
          (x) => {
            setBusy(false);
          }
        );
      }
      return;
    }

    setMenuAncr(ev.currentTarget);
  };

  const hGetLikes = () => {
    if (user) {
      setBusy(true);
      LiksAPI.getLikes(
        user,
        pid,
        (m) => {
          setBusy(false);
          setLiksCount(m.cnt);
          if (m.me > 0) {
            setIlikeit(true);
          } else {
            setIlikeit(false);
          }
        },
        (x) => {
          setBusy(false);
        }
      );
    }
  };

  React.useEffect(() => {
    if (pid) {
      hGetLikes();
    }

    return () => {
      setBusy(false);
    };
  }, [pid]);

  const getLikeIcon = () => {
    if (busy) return <CircularProgress size={22} />;

    if (iLikeit) return <ThumbUpIcon size={25} color="primary" />;

    return <ThumbUpOutlinedIcon size={25} color="primary" />;
  };

  return (
    <div className={classes.root}>
      <ButtonBase
        onClick={(ev) => {
          hToggleLike(ev);
        }}
      >
        {getLikeIcon()}
      </ButtonBase>

      <span style={{ color: "#333", minWidth: "35px", textAlign: "center" }}>
        {liksCount > 0 ? liksCount : ""}
      </span>

      <Menu
        anchorEl={menuAncr}
        open={menuAncr !== null}
        onClose={hCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={hSignin}>{texts.signin}</MenuItem>
      </Menu>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      marginLeft: "5px",
      marginRight: "5px",
    },
  },
}));

export default LikeControl;
