import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import { Container, Typography } from "@mui/material";

import AvaName from "./AvaName";
import Comments from "./Comments";
import PlayWindow from "../animate/PlayWindow";
import { getUrlParams, goHomePage, gotoPage } from "../tools/browserHelpers";

import {
  extractProject,
  checkValidProject,
} from "../project/ProjectOperations";

import { EditBtn } from "../tools/IconBtn";
import SocialShare from "./SocialShare";
import LikeControl from "./LikeControl";
import PCreateModal from "../project/CreateProjectModal";

import ProjectAPI from "../api/projectApi";
import { useSelector, useDispatch } from "react-redux";
import { loadProject } from "../data/project";

const Player = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector((s) => s.settings.user);
  const text = useSelector((s) => s.settings.texts.play);
  const project = useSelector((s) => s.project);

  const [openClone, setOpenClone] = useState(false);
  const [autherInfo, setAutherInfo] = useState(null);

  const hClonePjt = () => {
    if (user) {
      if (autherInfo.uid === user.id) {
        gotoPage("/editor?id=" + project.id);
        return;
      }
    }

    setOpenClone(true);
  };

  const loadCloudProject = (xid) => {
    ProjectAPI.getProject(
      user,
      "play",
      xid,
      (m) => {
        const pjt = extractProject(m);
        if (checkValidProject(pjt)) {
          setAutherInfo({
            uid: m.uid,
            uname: m.uname,
            ava_img: m.ava_img,
          });
          dispatch(loadProject(pjt));
        } else {
          goHomePage();
        }
      },
      (x) => {
        goHomePage();
      }
    );
  };

  useEffect(() => {
    var xid = null;

    const urlParam = getUrlParams();
    if (urlParam) {
      if (urlParam.id !== undefined) {
        xid = urlParam.id;
      }
    }

    if (xid) {
      loadCloudProject(xid);
    } else {
      goHomePage();
    }
  }, []);

  const showCloneBtn = () => {
    if (project) {
      if (project.share !== undefined) {
        if (project.share > 0) {
          return (
            <>
              <EditBtn
                onClick={() => {
                  hClonePjt();
                }}
              />
              <span style={{ margin: "0px 3px" }}>{text.edit}</span>
            </>
          );
        }
      }
    }

    return <></>;
  };

  const isSample = () => {
    if (autherInfo) {
      if (autherInfo.uid == 10) {
        return (
          <div className={classes.smpQute}>
            <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
              {text.samplesQute}
            </Typography>
          </div>
        );
      }

      return (
        <>
          <AvaName autherInfo={autherInfo} />

          <hr />
          <Comments text={text} />
        </>
      );
    }

    return <></>;
  };

  return (
    <Container className={classes.root} maxWidth="md">
      <div className={classes.player}>
        <PlayWindow />
      </div>

      <div className={classes.title}>
        <span>{project.title}</span>
      </div>

      <div className={classes.data}>
        <div>{showCloneBtn()}</div>
        <div></div>
        <div>
          <SocialShare />
        </div>
        <div>
          <LikeControl />
        </div>
      </div>
      <hr />

      {isSample()}

      <PCreateModal
        open={openClone}
        close={() => {
          setOpenClone(false);
        }}
        basePjt={project}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    padding: theme.spacing(1),
  },
  player: {
    width: "100%",
    padding: "1px",
  },
  title: {
    width: "100%",
    fontWeight: "500",
    padding: theme.spacing(1),
    fontSize: 18,
  },
  data: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: theme.spacing(1),
    fontSize: 16,
  },
  smpQute: {
    position: "relative",
    display: "block",
    width: "100%",
    padding: theme.spacing(3, 1),
  },
}));

export default Player;
