import React, { useEffect, useState } from "react";

import { makeStyles } from "@mui/styles";
import { Container, Stack, Avatar, IconButton, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";

import { DIR_CDN_IMG } from "../data/adresses";

import CommentsAPI from "../api/commApi";
import { useSelector } from "react-redux";
import LoadMore from "../tools/LoadMore";

const Comments = ({ text }) => {
  const classes = useStyles();
  const user = useSelector((s) => s.settings.user);
  const project = useSelector((s) => s.project);

  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [sending, setSending] = useState(false);
  const [commTxt, setCommTxt] = useState("");
  const [coms, setComs] = useState([]);

  const loadComments = (ofst) => {
    if (loading) return;

    if (project) {
      if (project.id) {
        setLoading(true);
        CommentsAPI.getComments(
          project.id,
          ofst,
          (m) => {
            setLoading(false);
            if (Array.isArray(m)) {
              if (ofst > 0) {
                setComs([...coms, ...m]);
              } else {
                setComs(m);
              }
            }
          },
          (x) => {
            setLoading(false);
          }
        );
      }
    }
  };

  const hAddComm = () => {
    if (project) {
      if (project.id) {
        if (user && commTxt.length > 0) {
          setSending(true);
          const cc = { pid: project.id, txt: commTxt };
          CommentsAPI.AddComment(
            user,
            cc,
            (m) => {
              setSending(false);
              setCommTxt("");
              loadComments(0);
            },
            (x) => {
              setSending(false);
            }
          );
        }
      }
    }
  };

  const hDelete = (id) => {
    if (user) {
      setDeleting(true);
      CommentsAPI.DelComment(
        user,
        id,
        (m) => {
          setDeleting(false);
          var cc = [];
          coms.forEach((c) => {
            if (c.id !== id) cc.push(c);
          });
          setComs(cc);
        },
        (x) => {
          setDeleting(false);
        }
      );
    }
  };

  const hLoadMore = () => {
    loadComments(coms.length);
  };

  useEffect(() => {
    loadComments(0);
  }, [project]);

  const showDelBtn = (cm) => {
    if (user) {
      if (cm.uid === user.id && deleting === false) {
        return (
          <div className={classes.delBtn}>
            <IconButton
              aria-label="delete"
              title=""
              size="small"
              onClick={() => {
                if (!deleting) hDelete(cm.id);
              }}
            >
              <DeleteIcon style={{ fontSize: 25, color: "#555" }} />
            </IconButton>
          </div>
        );
      }
    }

    return <></>;
  };

  const printComms = () => {
    if (Array.isArray(coms)) {
      return coms.map((cm, i) => (
        <div key={i} className={classes.section}>
          <div className={classes.comment}>
            <Avatar
              className={classes.avaImg}
              src={cm.ava_img ? DIR_CDN_IMG + cm.ava_img : null}
            >
              {cm.uname[0].toUpperCase()}
            </Avatar>
            <div className={classes.cmData}>
              <div className={classes.author}>{cm.uname}</div>
              <div className={classes.cmTxt}>{cm.txt}</div>
            </div>
            {showDelBtn(cm)}
          </div>
        </div>
      ));
    }
  };

  return (
    <Container className={classes.root} maxWidth="md">
      <Stack spacing={1}>
        <div className={classes.section}>
          <TextField
            label=""
            variant="outlined"
            fullWidth
            multiline
            value={commTxt}
            onChange={(event) => {
              setCommTxt(event.target.value);
            }}
          />
        </div>

        <div className={classes.section}>
          <LoadingButton
            variant="contained"
            loading={sending}
            sx={{ margin: 2 }}
            onClick={() => {
              if (!sending) hAddComm();
            }}
          >
            {text.addcomment}
          </LoadingButton>
        </div>

        <div className={classes.section}>{printComms()}</div>

        <div className={classes.section}>
          <LoadMore
            loading={loading}
            onClick={() => {
              hLoadMore();
            }}
          />
        </div>
      </Stack>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "150px",
    padding: theme.spacing(2, 0),
  },
  section: {
    position: "relative",
    display: "block",
    width: "100%",
    margin: 0,
    padding: 0,
  },
  comment: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    padding: theme.spacing(1),
  },
  avaImg: {
    position: "relative",
    display: "block",
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: theme.spacing(1),
  },
  cmData: {
    position: "relative",
    display: "block",
    margin: 0,
    padding: 0,
    flexGrow: 1,
  },
  author: {
    position: "relative",
    display: "block",
    width: "180px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: "16px",
    fontWeight: 550,
  },
  cmTxt: {
    position: "relative",
    display: "block",
    width: "100%",
    wordWrap: "break-word",
    padding: theme.spacing(1),
  },
  delBtn: {
    position: "relative",
    display: "block",
  },
}));

export default Comments;
