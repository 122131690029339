import React from "react";
import { Provider } from "react-redux";
import { playStore } from "../coms/data/store";

import Seo from "../coms/global/Seo";
import MainFrame from "../coms/global/MainFrame";
import Layout from "../coms/global/Layout";
import Player from "../coms/player/Player";

const PlayPage = () => {
  return (
    <>
      <Seo title="Play Your Smart Application" pathname="/play" />
      <Provider store={playStore}>
        <MainFrame>
          <Layout>
            <Player />
          </Layout>
        </MainFrame>
      </Provider>
    </>
  );
};

export default PlayPage;
