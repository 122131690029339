import React from "react";

import { makeStyles } from "@mui/styles";
import { ButtonBase, Typography, Avatar } from "@mui/material";

import { gotoPage } from "../tools/browserHelpers";
import { DIR_CDN_IMG } from "../data/adresses";

const AvaName = ({ autherInfo }) => {
  const classes = useStyles();

  if (autherInfo) {
    return (
      <div className={classes.root}>
        <ButtonBase
          className={classes.action}
          onClick={() => {
            gotoPage("/profile?id=" + autherInfo.uid);
          }}
        >
          <Avatar
            src={autherInfo.ava_img ? DIR_CDN_IMG + autherInfo.ava_img : null}
            sx={{ width: 65, height: 65 }}
          >
            {autherInfo.uname[0].toUpperCase()}
          </Avatar>
          <Typography variant="h6" gutterBottom className={classes.auther}>
            {autherInfo.uname}
          </Typography>
        </ButtonBase>
      </div>
    );
  }

  return <></>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    padding: theme.spacing(2, 1),
  },
  auther: {
    width: "100%",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    padding: theme.spacing(0, 1),
  },
}));

export default AvaName;
