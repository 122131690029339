import SecureAPI from "./SecureAPI";
import { API_LIKES } from "../data/adresses";

class LikesAPI extends SecureAPI {
  constructor() {
    super(API_LIKES);
  }

  async setLike(user, pid, onOk, onErr = null) {
    await this.Get(user, "/set?pid=" + pid, onOk, onErr);
  }

  async clearLike(user, pid, onOk, onErr = null) {
    await this.Get(user, "/clr?pid=" + pid, onOk, onErr);
  }

  async getLikes(user, pid, onOk, onErr = null) {
    await this.Get(user, "/get?pid=" + pid, onOk, onErr);
  }
}

export default new LikesAPI();
