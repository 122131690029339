import SecureAPI from "./SecureAPI";
import { API_COMMS } from "../data/adresses";

class CommentsAPI extends SecureAPI {
  constructor() {
    super(API_COMMS);
  }

  async getComments(pid, offset, onOk, onErr = null) {
    await this.Get(null, "/list?pid=" + pid + "&of=" + offset, onOk, onErr);
  }

  async AddComment(user, payload, onOk, onErr = null) {
    await this.Post(user, "/add", payload, onOk, onErr);
  }

  async DelComment(user, id, onOk, onErr = null) {
    await this.Get(user, "/del?id=" + id, onOk, onErr);
  }
}

export default new CommentsAPI();
